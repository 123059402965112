<template>
  <v-container fluid style="height:100%" id="app-container">
    <portal to="app-header">
      {{ $t('modules.traceabilityTaco') }}
    </portal>
    <div class="d-flex justify-space-between">
      <span class="title">Search</span>
      <span>
        <v-btn
          small
          color="primary"
          class="text-none ml-4"
          @click="exportData"
          :disabled="rowData.length < 1"
        >
          Download
        </v-btn>
        <v-btn
          small
          color="primary"
          class="text-none ml-4"
          @click="fetchReport"
          :disabled="rowData.length < 1"
          :loading="loading"
        >
          Refresh
        </v-btn>
      </span>
    </div>
    <v-row style="height:100%">
      <v-col cols="12" sm="4" lg="3" xl="2">
        <v-form ref="form" v-model="isValid" @submit.prevent="fetchReport">
          <v-text-field
            label="Barcode"
            v-model="barcode"
            outlined
            dense
            :rules="[(v) => !!v || 'Field is required']"
          ></v-text-field>
          <v-select
            :items="reportTypes"
            label="Type"
            dense
            outlined
            v-model="type"
          ></v-select>
          <v-btn
            block
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="!isValid || loading"
          >
            Search
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="12" sm="8" lg="9" xl="10">
        <ag-grid
          ref="grid"
          :rowData="rowData"
          :columnDefs="columnDefs"
          :rowGroupPanelShow="'false'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import AgGrid from '@shopworx/ui-components/grid/AgGrid.vue';

export default {
  name: 'Traceablity',
  components: {
    AgGrid,
    // FilterSidebar,
  },
  data() {
    return {
      loading: false,
      rowData: [],
      columnDefs: [],
      qualityColumns: [{
        headerName: 'Line Name',
        field: 'linename',
        colId: 'linename',
      }, {
        headerName: 'Machine Name',
        field: 'machinename',
        colId: 'machinename',
      }, {
        headerName: 'Customer',
        field: 'customer',
        colId: 'customer',
      }, {
        headerName: 'Model',
        field: 'model',
        colId: 'model',
      }, {
        headerName: 'Variant',
        field: 'variant',
        colId: 'variant',
      }, {
        headerName: 'Barcode',
        field: 'barcode',
        colId: 'barcode',
      }, {
        headerName: 'Parameter Name',
        field: 'parameter',
        colId: 'parameter',
      }, {
        headerName: 'Defect Name',
        field: 'defectname',
        colId: 'defectname',
      }, {
        headerName: 'Defect Code',
        field: 'defectcode',
        colId: 'defectcode',
      }, {
        headerName: 'Type',
        field: 'type',
        colId: 'type',
      }, {
        headerName: 'Operater Name',
        field: 'operatorname',
        colId: 'operatorname',
      }, {
        headerName: 'Created At',
        field: 'createdTimestamp',
        colId: 'createdTimestamp',
      }],
      traceabilityColumns: [{
        headerName: 'Machine Name',
        field: 'machinename',
        colId: 'machinename',
      }, {
        headerName: 'Battery Pack Barcode',
        field: 'batterypackbarcode',
        colId: 'batterypackbarcode',
      }, {
        headerName: 'Pack Barcode',
        field: 'packbarcode',
        colId: 'packbarcode',
      }, {
        headerName: 'Module Barcode',
        field: 'modulebarcode',
        colId: 'modulebarcode',
      }, {
        headerName: 'Cell Barcode',
        field: 'cellbarcode',
        colId: 'cellbarcode',
      }, {
        headerName: 'Gear',
        field: 'gear',
        colId: 'gear',
      }, {
        headerName: 'Voltage',
        field: 'voltage',
        colId: 'voltage',
      }, {
        headerName: 'Resistance',
        field: 'resistance',
        colId: 'resistance',
      }, {
        headerName: 'Gun Name',
        field: 'gunname',
        colId: 'gunname',
      }, {
        headerName: 'Peak Torque',
        field: 'peaktorque',
        colId: 'peaktorque',
      }, {
        headerName: 'Peak Angle',
        field: 'peakangle',
        colId: 'peakangle',
      }, {
        headerName: 'Nominal Torque',
        field: 'nominaltorque',
        colId: 'nominaltorque',
      }, {
        headerName: 'Torque High Limit',
        field: 'torquehighlimit',
        colId: 'torquehighlimit',
      }, {
        headerName: 'Cycle Result',
        field: 'cycleresult',
        colId: 'cycleresult',
      }, {
        headerName: 'Torque Low Limit',
        field: 'torquelowlimit',
        colId: 'torquelowlimit',
      }, {
        headerName: 'Torque Status',
        field: 'torquestatus',
        colId: 'torquestatus',
      }, {
        headerName: 'Quality Result',
        field: 'qualityresult',
        colId: 'qualityresult',
      }, {
        headerName: 'Parameter',
        field: 'parameter',
        colId: 'parameter',
      }, {
        headerName: 'Checklist Type',
        field: 'checklisttype',
        colId: 'checklisttype',
      }, {
        headerName: 'Value',
        field: 'value',
        colId: 'value',
      }, {
        headerName: 'Created At',
        field: 'createdTimestamp',
        colId: 'createdTimestamp',
      }],
      barcode: '',
      isValid: false,
      gridApi: null,
    };
  },
  computed: {
    ...mapState('traceabilityTaco', ['reportTypes', 'selectedType']),
    type: {
      get() {
        return this.selectedType;
      },
      set(type) {
        this.columnDefs = type === 'Quality' ? this.qualityColumns : this.traceabilityColumns;
        this.rowData = [];
        this.setSelectedType(type);
      },
    },
    gridOptions() {
      return this.$refs.grid.gridOptions;
    },
  },
  beforeMount() {
    this.gridOptions = {
      stopEditingWhenGridLosesFocus: true,
    };
  },
  mounted() {
    this.columnDefs = this.selectedType === 'Quality' ? this.qualityColumns : this.traceabilityColumns;
    this.gridApi = this.gridOptions.api;
    this.gridApi.sizeColumnsToFit();
  },
  methods: {
    ...mapActions('traceabilityTaco', ['getReport']),
    ...mapMutations('traceabilityTaco', ['setSelectedType']),
    async fetchReport() {
      this.loading = true;

      // Determine reportName based on selectedType
      const reportName = this.selectedType === 'Quality' ? 'defecttraceability' : 'traceability';

      // Set payload
      const payload = { packbarcodeVal: `{${this.barcode}}` };

      // Get report data
      const report = await this.getReport({ payload, reportName });

      if (this.selectedType === 'Quality') {
        // Process Quality report data
        if (report.reportData.length && report.reportData[0].defectdata) {
          this.rowData = report.reportData[0].defectdata.map((r) => ({ ...r }));
        }
      } else {
        // Process other report types
        const rowDataArray = [];

        if (report.reportData.length) {
          const { cellscanning, machinedata } = report.reportData[0];

          if (cellscanning) {
            cellscanning.forEach((r) => {
              rowDataArray.push({ searchedbarcode: this.barcode, ...r });
            });
          }

          if (machinedata) {
            machinedata.forEach((m) => {
              const processData = (data, prop) => {
                if (report.reportData[0][prop]) {
                  const dataWithProp = report.reportData[0][prop]
                    .filter((t) => t.machinename === m.machinename);
                  if (dataWithProp) {
                    dataWithProp.forEach((d) => {
                      if (!d.packbarcode && d.barcode) {
                        d.packbarcode = d.barcode;
                      }
                      rowDataArray.push({ searchedbarcode: this.barcode, ...d });
                    });
                  }
                }
              };
              processData(report.reportData[0].machinedata, 'machinedata');
              processData(report.reportData[0].torquedata, 'torquedata');
              processData(report.reportData[0].checklistdata, 'checklistdata');
              processData(report.reportData[0].pdicantestparameterresult, 'pdicantestparameterresult');
              processData(report.reportData[0].cantestparameterresult, 'cantestparameterresult');
              processData(report.reportData[0].childparts, 'childparts');
            });
          }
        }
        this.rowData = [...rowDataArray];
      }

      this.loading = false;
    },
    dateFormat(val) {
      const date = new Date(val);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);

      return parseInt(`${year}${month}${day}`, 10);
    },
    fetchRecords(e) {
      this.$refs.grid[0].setRowData(e);
    },
    async exportData() {
      let fileName = '';
      if (this.selectedType === 'Quality') {
        fileName = 'Defect Traceability';
      } else {
        fileName = 'Traceability';
      }
      fileName = `${fileName}-${this.barcode}-${new Date().getTime()}`;
      const params = {
        fileName,
        sheetName: 'Sheet1',
      };
      this.gridApi.exportDataAsExcel(params);
    },
  },
  async created() {
    this.loading = true;
    this.loading = false;
  },
};
</script>

<style>
.v-window__container {
  height: 100%;
}
</style>
